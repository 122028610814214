import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <span className="title">
          <span className="font-regular">Patrick</span>{" "}
          <span className="font-bold">Oleksy</span>
          <br />
          <span className="title subtitle">Digital Artist</span>
        </span>
      </header>
      <section className="App-content">
        <section className="row">
          <section className="column">
            <video
              width="100%"
              height="550px"
              controls
              muted={true}
              autoPlay={true}
              loop={true}
            >
              <source
                src={require("./videos/pineapple.mp4")}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </section>
          <section className="column last">
            <video
              width="100%"
              height="550px"
              controls
              muted={true}
              autoPlay={true}
              loop={true}
            >
              <source src={require("./videos/pong2.mp4")} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </section>
        </section>
        <section className="row">
          <section className="column last">
            <iframe
              width="100%"
              height="750px"
              src="https://www.youtube.com/embed/qmw1yxPjqqg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </section>
        </section>
        <section className="row">
          <section className="column">
            <img
              src={require("./images/Landing00.jpg")}
              alt=""
              className="image"
            />
            <img
              src={require("./images/Landing01.jpg")}
              alt=""
              className="image"
            />
            <img
              src={require("./images/Landing02.jpg")}
              alt=""
              className="image"
            />
            <img
              src={require("./images/DefaultHome.jpg")}
              alt=""
              className="image"
            />
          </section>
          <section className="column">
            <img
              src={require("./images/More Options Expanded Logged Out.jpg")}
              alt=""
              className="image"
            />
            <img
              src={require("./images/Sample Homescreen Playlist.jpg")}
              alt=""
              className="image"
            />
          </section>
          <section className="column last">
            <img
              src={require("./images/Playlist Library.jpg")}
              alt=""
              className="image"
            />
            <img src={require("./images/Login.jpg")} alt="" className="image" />
            <img
              src={require("./images/Song Selection.png")}
              alt=""
              className="image"
            />
          </section>
        </section>
        <section className="row">
          <section className="column-last">
            <video
              width="100%"
              height="auto"
              controls
              muted={true}
              autoPlay={true}
              loop={true}
            >
              <source
                src={require("./videos/MovingLines.mp4")}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </section>
        </section>
        <section className="row">
          <section className="column">
            <video
              width="100%"
              height="auto"
              controls
              muted={true}
              autoPlay={true}
              loop={true}
            >
              <source src={require("./videos/pop.mp4")} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </section>
          <section className="column last">
            <video
              width="100%"
              height="auto"
              controls
              muted={true}
              autoPlay={true}
              loop={true}
            >
              <source src={require("./videos/Biology.mp4")} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </section>
        </section>
      </section>
      <section className="App-footer">
        <section className="row">
          <p>&copy; Patrick Oleksy 2022</p>
        </section>
      </section>
    </div>
  );
}

export default App;
